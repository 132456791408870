@font-face {
  font-family: 'hoylu-ui-icons-centered';
  src:  url('hoylu-ui-icons-centered.eot?l6xem8');
  src:  url('hoylu-ui-icons-centered.eot?l6xem8#iefix') format('embedded-opentype'),
    url('hoylu-ui-icons-centered.ttf?l6xem8') format('truetype'),
    url('hoylu-ui-icons-centered.woff?l6xem8') format('woff'),
    url('hoylu-ui-icons-centered.svg?l6xem8#hoylu-ui-icons-centered') format('svg');
  font-weight: normal;
  font-style: normal;
}

.hicon {
  font-family: 'hoylu-ui-icons-centered' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hicon.weinspire:before {content: "\e600";}
.hicon.delete:before {content: "\e601";}
.hicon.print:before {content: "\e602";}
.hicon.email:before {content: "\e603";}
.hicon.camera:before {content: "\e604";}
.hicon.document:before {content: "\e605";}
.hicon.save:before {content: "\e606";}
.hicon.import:before {content: "\e607";}
.hicon.export:before {content: "\e608";}
.hicon.help:before {content: "\e609";}
.hicon.clock:before {content: "\e60A";}
.hicon.alarmclock:before {content: "\e60B";}
.hicon.launch:before {content: "\e60C";}
.hicon.locked:before {content: "\e60D";}
.hicon.unlocked:before {content: "\e60E";}
.hicon.star:before {content: "\e60F";}
.hicon.label:before {content: "\e610";}
.hicon.location:before {content: "\e611";}
.hicon.comment:before {content: "\e612";}
.hicon.bookmark:before {content: "\e613";}
.hicon.folder:before {content: "\e614";}
.hicon.folderopen:before {content: "\e615";}
.hicon.folderadd:before {content: "\e616";}
.hicon.folderremove:before {content: "\e617";}
.hicon.harddisk:before {content: "\e618";}
.hicon.home:before {content: "\e619";}
.hicon.cloud:before {content: "\e61A";}
.hicon.cloudupload:before {content: "\e61B";}
.hicon.clouddownload:before {content: "\e61C";}
.hicon.cloudcheck:before {content: "\e61D";}
.hicon.quill:before {content: "\e61E";}
.hicon.pen:before {content: "\e61F";}
.hicon.keyboard:before {content: "\e620";}
.hicon.overview:before {content: "\e621";}
.hicon.question:before {content: "\e622";}
.hicon.exclamation:before {content: "\e623";}
.hicon.info:before {content: "\e624";}
.hicon.magnifier:before {content: "\e625";}
.hicon.magnifierplus:before {content: "\e626";}
.hicon.magnifierminus:before {content: "\e627";}
.hicon.person:before {content: "\e628";}
.hicon.personadd:before {content: "\e629";}
.hicon.personremove:before {content: "\e62A";}
.hicon.persons:before {content: "\e62B";}
.hicon.reload:before {content: "\e62C";}
.hicon.sync:before {content: "\e62D";}
.hicon.settings:before {content: "\e62E";}
.hicon.more:before {content: "\e62F";}
.hicon.morevertical:before {content: "\e630";}
.hicon.sliders:before {content: "\e631";}
.hicon.plus:before {content: "\e632";}
.hicon.minus:before {content: "\e633";}
.hicon.cancel:before {content: "\e634";}
.hicon.check:before {content: "\e635";}
.hicon.undo:before {content: "\e636";}
.hicon.redo:before {content: "\e637";}
.hicon.down:before {content: "\e638";}
.hicon.left:before {content: "\e639";}
.hicon.up:before {content: "\e63A";}
.hicon.right:before {content: "\e63B";}
.hicon.triangledown:before {content: "\e63C";}
.hicon.triangleleftdown:before {content: "\e63D";}
.hicon.triangleleft:before {content: "\e63E";}
.hicon.triangleleftup:before {content: "\e63F";}
.hicon.triangleup:before {content: "\e640";}
.hicon.trianglerightup:before {content: "\e641";}
.hicon.triangleright:before {content: "\e642";}
.hicon.trianglerightdown:before {content: "\e643";}
.hicon.triangles:before {content: "\e644";}
.hicon.trianglesvertical:before {content: "\e645";}
.hicon.arrowdown:before {content: "\e646";}
.hicon.arrowleftdown:before {content: "\e647";}
.hicon.arrowleft:before {content: "\e648";}
.hicon.arrowleftup:before {content: "\e649";}
.hicon.arrowup:before {content: "\e64A";}
.hicon.arrowrightup:before {content: "\e64B";}
.hicon.arrowright:before {content: "\e64C";}
.hicon.arrowrightdown:before {content: "\e64D";}
.hicon.toback:before {content: "\e64E";}
.hicon.tofront:before {content: "\e64F";}
.hicon.cut:before {content: "\e650";}
.hicon.copy:before {content: "\e651";}
.hicon.paste:before {content: "\e652";}
.hicon.downloadpaper:before {content: "\e653";}
.hicon.background:before {content: "\e654";}
.hicon.web:before {content: "\e655";}
.hicon.windowmaximize:before {content: "\e656";}
.hicon.expandbox:before {content: "\e657";}
.hicon.shrinkbox:before {content: "\e658";}
.hicon.windowminimize:before {content: "\e659";}
.hicon.menu:before {content: "\e65A";}
.hicon.grid:before {content: "\e65B";}
.hicon.history:before {content: "\e65C";}
.hicon.navigate:before {content: "\e65D";}
.hicon.mouseleft:before {content: "\e65E";}
.hicon.mouseright:before {content: "\e65F";}
.hicon.mousemiddle:before {content: "\e660";}
.hicon.drawingoptions:before {content: "\e661";}
.hicon.selection:before {content: "\e662";}
.hicon.group:before {content: "\e663";}
.hicon.pin:before {content: "\e664";}
.hicon.bolt:before {content: "\e665";}
.hicon.heart:before {content: "\e666";}
.hicon.lightbulb:before {content: "\e667";}
.hicon.calendar:before {content: "\e668";}
.hicon.shoppingcart:before {content: "\e669";}
.hicon.todo:before {content: "\e66A";}
.hicon.discuss:before {content: "\e66B";}
.hicon.link:before {content: "\e66C";}
.hicon.flag:before {content: "\e66D";}
.hicon.voteup:before {content: "\e66E";}
.hicon.votedown:before {content: "\e66F";}
.hicon.show:before {content: "\e670";}
.hicon.hide:before {content: "\e671";}
.hicon.filter:before {content: "\e672";}
.hicon.dot:before {content: "\e673";}
.hicon.sortascending:before {content: "\e674";}
.hicon.sortdescending:before {content: "\e675";}
.hicon.text:before {content: "\e676";}
.hicon.numbers:before {content: "\e677";}
.hicon.usb:before {content: "\e678";}
.hicon.network:before {content: "\e679";}
.hicon.pc:before {content: "\e67A";}
.hicon.laptop:before {content: "\e67B";}
.hicon.tablet:before {content: "\e67C";}
.hicon.mobile:before {content: "\e67D";}
.hicon.watch:before {content: "\e67E";}
.hicon.phone:before {content: "\e67F";}
.hicon.tv:before {content: "\e680";}
.hicon.projector:before {content: "\e681";}
.hicon.questionsign:before {content: "\e682";}
.hicon.exclamationsign:before {content: "\e683";}
.hicon.cancelsign:before {content: "\e684";}
.hicon.checksign:before {content: "\e685";}
.hicon.newdocument:before {content: "\e686";}
.hicon.ungroup:before {content: "\e687";}
.hicon.share:before {content: "\e688";}
.hicon.favoritefolder:before {content: "\e689";}
.hicon.foldernavigation:before {content: "\e68A";}
.hicon.paperclip:before {content: "\e68B";}
.hicon.icons:before {content: "\e68C";}
.hicon.pagesall:before {content: "\e68D";}
.hicon.pagesgroup:before {content: "\e68E";}
.hicon.pagessingle:before {content: "\e68F";}
.hicon.flipvertically:before {content: "\e690";}
.hicon.fliphorizontally:before {content: "\e691";}
.hicon.rotatecw:before {content: "\e692";}
.hicon.rotateccw:before {content: "\e693";}
.hicon.repeat:before {content: "\e694";}
.hicon.nosync:before {content: "\e695";}
.hicon.dice0:before {content: "\e696";}
.hicon.dice0.dot:before {content: "\e81e";}
.hicon.dice0.checkmark:before {content: "\e814";}
.hicon.dice1:before {content: "\e697";}
.hicon.dice2:before {content: "\e698";}
.hicon.dice3:before {content: "\e699";}
.hicon.dice4:before {content: "\e69A";}
.hicon.dice5:before {content: "\e69B";}
.hicon.dice6:before {content: "\e69C";}
.hicon.dice7:before {content: "\e69D";}
.hicon.dice8:before {content: "\e69E";}
.hicon.dice9:before {content: "\e69F";}
.hicon.wesketch:before {content: "\e6A0";}
.hicon.wepaper:before {content: "\e6A1";}
.hicon.wepinboard:before {content: "\e6A2";}
.hicon.wecapture:before {content: "\e6A3";}
.hicon.vote1:before {content: "\e6A4";}
.hicon.vote2:before {content: "\e6A5";}
.hicon.vote3:before {content: "\e6A6";}
.hicon.vote3plus:before {content: "\e6A7";}
.hicon.commendadd:before {content: "\e6A8";}
.hicon.send:before {content: "\e6A9";}
.hicon.pdf:before {content: "\e6AA";}
.hicon.ppt:before {content: "\e6AB";}
.hicon.image:before {content: "\e6AC";}
.hicon.vector:before {content: "\e6AD";}
.hicon.zip:before {content: "\e6AE";}
.hicon.documentlandscape:before {content: "\e6AF";}
.hicon.documentstrokeout:before {content: "\e6B0";}
.hicon.pentip:before {content: "\e6B1";}
.hicon.pentipstrokeout:before {content: "\e6B2";}
.hicon.unpin:before {content: "\e6B3";}
.hicon.key:before {content: "\e6B4";}
.hicon.lab:before {content: "\e6B5";}
.hicon.starempty:before {content: "\e6B6";}
.hicon.starhalf:before {content: "\e6B7";}
.hicon.abcup:before {content: "\e6B8";}
.hicon.abcdown:before {content: "\e6B9";}
.hicon.timeup:before {content: "\e6BA";}
.hicon.timedown:before {content: "\e6BB";}
.hicon.freeze:before {content: "\e6BC";}
.hicon.wirelessconnection:before {content: "\e6BD";}
.hicon.bluetooth:before {content: "\e6BE";}
.hicon.split:before {content: "\e6BF";}
.hicon.browse:before {content: "\e6C0";}
.hicon.closemain:before {content: "\e6C1";}
.hicon.rowview:before {content: "\e6C2";}
.hicon.gridview:before {content: "\e6C3";}
.hicon.mindmap:before {content: "\e6C4";}
.hicon.pastetop:before {content: "\e6C5";}
.hicon.library:before {content: "\e6C6";}
.hicon.hand:before {content: "\e6C7";}
.hicon.pageleft:before {content: "\e6C8";}
.hicon.pagedown:before {content: "\e6C9";}
.hicon.pageright:before {content: "\e6CA";}
.hicon.pageup:before {content: "\e6CB";}
.hicon.voting:before {content: "\e6CC";}
.hicon.slidinggate:before {content: "\e6CD";}
.hicon.windowexpand:before {content: "\e6CE";}
.hicon.windowshrink:before {content: "\e6CF";}
.hicon.documentlandscapestrokeout:before {content: "\e6D0";}
.hicon.inbox:before {content: "\e6D1";}
.hicon.locationadd:before {content: "\e6D2";}
.hicon.locationremove:before {content: "\e6D3";}
.hicon.play:before {content: "\e6D4";}
.hicon.stop:before {content: "\e6D5";}
.hicon.pause:before {content: "\e6D6";}
.hicon.homeoverview:before {content: "\e6D7";}
.hicon.map:before {content: "\e6D8";}
.hicon.mapdetail:before {content: "\e6D9";}
.hicon.notepad:before {content: "\e6DA";}
.hicon.notepadstrokeout:before {content: "\e6DB";}
.hicon.unflag:before {content: "\e6DC";}
.hicon.mindmapremove:before {content: "\e6DD";}
.hicon.closemaindown:before {content: "\e6DE";}
.hicon.chameleon:before {content: "\e6DF";}
.hicon.autoupdate:before {content: "\e6E0";}
.hicon.foldershow:before {content: "\e6E1";}
.hicon.folderback:before {content: "\e6E2";}
.hicon.pinadd:before {content: "\e6E3";}
.hicon.folderhide:before {content: "\e6E4";}
.hicon.windowrestore:before {content: "\e6E5";}
.hicon.secure:before {content: "\e6E6";}
.hicon.plugin:before {content: "\e6E7";}
.hicon.bug:before {content: "\e6E8";}
.hicon.target:before {content: "\e6E9";}
.hicon.bistribute:before {content: "\e6EA";}
.hicon.cake:before {content: "\e6EB";}
.hicon.award:before {content: "\e6EC";}
.hicon.ranking:before {content: "\e6ED";}
.hicon.medal:before {content: "\e6EE";}
.hicon.cash:before {content: "\e6EF";}
.hicon.line.very.thin:before {content: "\e6F0";}
.hicon.line.thin:before {content: "\e6F1";}
.hicon.line.medium:before {content: "\e6F2";}
.hicon.line.thick:before {content: "\e6F3";}
.hicon.line.very.thick:before {content: "\e6F4";}
.hicon.line.dotted:before {content: "\e6F5";}
.hicon.line.dashed:before {content: "\e6F6";}
.hicon.line.arrowhead:before {content: "\e6F7";}
.hicon.line.circle:before {content: "\e6F8";}
.hicon.line.square:before {content: "\e6F9";}
.hicon.stroke.very.thin:before {content: "\e6FA";}
.hicon.stroke.thin:before {content: "\e6FB";}
.hicon.stroke.medium:before {content: "\e6FC";}
.hicon.stroke.thick:before {content: "\e6FD";}
.hicon.stroke.very.thick:before {content: "\e6FE";}
.hicon.opacity20:before {content: "\e6FF";}
.hicon.opacity40:before {content: "\e700";}
.hicon.opacity60:before {content: "\e701";}
.hicon.opacity80:before {content: "\e702";}
.hicon.opacity100:before {content: "\e703";}
.hicon.erasertool:before {content: "\e704";}
.hicon.pathtool:before {content: "\e705";}
.hicon.brush5tool:before {content: "\e706";}
.hicon.brush4tool:before {content: "\e707";}
.hicon.brush3tool:before {content: "\e708";}
.hicon.brush2tool:before {content: "\e709";}
.hicon.brush1tool:before {content: "\e70A";}
.hicon.penciltool:before {content: "\e70B";}
.hicon.markertool:before {content: "\e70C";}
.hicon.highlightertool:before {content: "\e70D";}
.hicon.measuretool:before {content: "\e70E";}
.hicon.rulertool:before {content: "\e70F";}
.hicon.freeformshapetool:before {content: "\e710";}
.hicon.rectangletool:before {content: "\e711";}
.hicon.ellipsestool:before {content: "\e712";}
.hicon.triangletool:before {content: "\e713";}
.hicon.polygontool:before {content: "\e714";}
.hicon.lassotool:before {content: "\e715";}
.hicon.harpoontool:before {content: "\e716";}
.hicon.penpainting:before {content: "\e717";}
.hicon.pendown:before {content: "\e718";}
.hicon.penup:before {content: "\e719";}
.hicon.pendownlowbattery:before {content: "\e71A";}
.hicon.penuplowbattery:before {content: "\e71B";}
.hicon.penoff:before {content: "\e71C";}
.hicon.penofflowbattery:before {content: "\e71D";}
.hicon.showonscreen:before {content: "\e71E";}
.hicon.calibrate:before {content: "\e71F";}
.hicon.mouse:before {content: "\e720";}
.hicon.fingerpainting:before {content: "\e721";}
.hicon.sortbyrow:before {content: "\e722";}
.hicon.sortbycolumn:before {content: "\e723";}
.hicon.sketches:before {content: "\e724";}
.hicon.checkboxchecked:before {content: "\e725";}
.hicon.checkboxunchecked:before {content: "\e726";}
.hicon.enlarge:before {content: "\e727";}
.hicon.shrink:before {content: "\e728";}
.hicon.fingertap:before {content: "\e729";}
.hicon.fingerswipeew:before {content: "\e72A";}
.hicon.fingerswipens:before {content: "\e72B";}
.hicon.pentap:before {content: "\e72C";}
.hicon.penswipeew:before {content: "\e72D";}
.hicon.penswipens:before {content: "\e72E";}
.hicon.moustache:before {content: "\e72F";}
.hicon.autosave:before {content: "\e730";}
.hicon.iconshape:before {content: "\e731";}
.hicon.desktopbundleicon:before {content: "\e732";}
.hicon.displaybundleicon:before {content: "\e733";}
.hicon.wallbundleicon:before {content: "\e734";}
.hicon.strokes:before {content: "\e735";}
.hicon.smallerasertool:before {content: "\e736";}
.hicon.mediumerasertool:before {content: "\e737";}
.hicon.largeerasertool:before {content: "\e738";}
.hicon.lineerasertool:before {content: "\e739";}
.hicon.intersectionerasertool:before {content: "\e73A";}
.hicon.styleicon:before {content: "\e73B";}
.hicon.recolor:before {content: "\e73C";}
.hicon.textrecognition:before {content: "\e73D";}
.hicon.cogwheelplay:before {content: "\e73E";}
.hicon.settings2:before {content: "\e73F";}
.hicon.switchapps:before {content: "\e740";}
.hicon.pageupload:before {content: "\e741";}
.hicon.windowfullscreen:before {content: "\e742";}
.hicon.windowexitfullscreen:before {content: "\e743";}
.hicon.dockright:before {content: "\e744";}
.hicon.dockleft:before {content: "\e745";}
.hicon.windowclose:before {content: "\e746";}
.hicon.splash:before {content: "\e747";}
.hicon.followlink:before {content: "\e748";}
.hicon.unlink:before {content: "\e749";}
.hicon.doublearrowdown:before {content: "\e74A";}
.hicon.doublearrowleftdown:before {content: "\e74B";}
.hicon.doublearrowleft:before {content: "\e74C";}
.hicon.doublearrowleftup:before {content: "\e74D";}
.hicon.doublearrowup:before {content: "\e74E";}
.hicon.doublearrowrightup:before {content: "\e74F";}
.hicon.doublearrowright:before {content: "\e750";}
.hicon.doublearrowrightdown:before {content: "\e751";}
.hicon.cornersnwse:before {content: "\e752";}
.hicon.cornersswne:before {content: "\e753";}
.hicon.robot:before {content: "\e754";}
.hicon.presenter:before {content: "\e755";}
.hicon.indexfingerup:before {content: "\e756";}
.hicon.pagecolor:before {content: "\e757";}
.hicon.flownote:before {content: "\e758";}
.hicon.strokesbackground:before {content: "\e759";}
.hicon.pasteinplace:before {content: "\e75A";}
.hicon.az:before {content: "\e75B";}
.hicon.infinity:before {content: "\e75C";}
.hicon.binoculars:before {content: "\e75D";}
.hicon.hoylu:before {content: "\e872";}
.hicon.hoylu.flow:before {content: "\e75F";}
.hicon.hoylu.sketch:before {content: "\e760";}
.hicon.hoylu.paper:before {content: "\e761";}
.hicon.hoylu.capture:before {content: "\e762";}
.hicon.hoylu.central:before {content: "\e763";}
.hicon.hoylu.show:before {content: "\e764";}
.hicon.hoylu.insight:before {content: "\e765";}
.hicon.hoylu.negative:before {content: "\e766";}
.hicon.upload:before {content: "\e767";}
.hicon.download:before {content: "\e768";}
.hicon.cloudsyncing:before {content: "\e769";}
.hicon.shipswheel:before {content: "\e76A";}
.hicon.cloud.flow:before {content: "\e76B";}
.hicon.cloud.sketch:before {content: "\e76C";}
.hicon.cloud.paper:before {content: "\e76D";}
.hicon.cloud.capture:before {content: "\e76E";}
.hicon.hoylu2:before {content: "\e76F";}
.hicon.hoylu2negative:before {content: "\e770";}
.hicon.branchoffarrows:before {content: "\e771";}
.hicon.circle.flow:before {content: "\e772";}
.hicon.circle.sketch:before {content: "\e773";}
.hicon.circle.paper:before {content: "\e774";}
.hicon.circle.capture:before {content: "\e775";}
.hicon.start.flow:before {content: "\e776";}
.hicon.start.sketch:before {content: "\e777";}
.hicon.start.paper:before {content: "\e778";}
.hicon.whiteboardpresets:before {content: "\e779";}
.hicon.sketchingpresets:before {content: "\e77A";}
.hicon.custompresets:before {content: "\e77B";}
.hicon.privateaccess:before {content: "\e77C";}
.hicon.protectedaccess:before {content: "\e77D";}
.hicon.editing.disabled:before {content: "\e77E";}
.hicon.editing.locked:before {content: "\e77F";}
.hicon.sendprotected:before {content: "\e780";}
.hicon.cloud.folder:before {content: "\e781";}
.hicon.personal.folder:before {content: "\e782";}
.hicon.synced.folder:before {content: "\e783";}
.hicon.forward.folder:before {content: "\e784";}
.hicon.increase:before {content: "\e786";}
.hicon.retain:before {content: "\e787";}
.hicon.communicate:before {content: "\e788";}
.hicon.unlimited:before {content: "\e789";}
.hicon.shareoptions:before {content: "\e78A";}
.hicon.import2:before {content: "\e78B";}
.hicon.share2:before {content: "\e78C";}
.hicon.searchinbox:before {content: "\e78D";}
.hicon.overview2:before {content: "\e790";}
.hicon.combined.brush00:before {content: "\e793";}
.hicon.combined.brush01:before {content: "\e794";}
.hicon.combined.brush02:before {content: "\e795";}
.hicon.combined.brush03:before {content: "\e796";}
.hicon.combined.brush04:before {content: "\e797";}
.hicon.combined.brush05:before {content: "\e798";}
.hicon.combined.brush06:before {content: "\e799";}
.hicon.combined.brush07:before {content: "\e79A";}
.hicon.combined.brush10:before {content: "\e79D";}
.hicon.combined.brush11:before {content: "\e79E";}
.hicon.combined.brush12:before {content: "\e79F";}
.hicon.combined.brush13:before {content: "\e7A0";}
.hicon.combined.brush14:before {content: "\e7A1";}
.hicon.combined.brush15:before {content: "\e7A2";}
.hicon.combined.brush16:before {content: "\e7A3";}
.hicon.combined.brush17:before {content: "\e7A4";}
.hicon.dots2.all:before {content: "\e7A7";}
.hicon.dots2.left:before {content: "\e7A8";}
.hicon.dots2.right:before {content: "\e7A9";}
.hicon.dots3.all:before {content: "\e7AA";}
.hicon.dots3.left:before {content: "\e7AB";}
.hicon.dots3.middle:before {content: "\e7AC";}
.hicon.dots3.right:before {content: "\e7AD";}
.hicon.toolset.brush4:before {content: "\e7B1";}
.hicon.toolset.brush3:before {content: "\e7B2";}
.hicon.toolset.brush2:before {content: "\e7B3";}
.hicon.toolset.brush1:before {content: "\e7B4";}
.hicon.toolset.brush0:before {content: "\e7B5";}
.hicon.toolset.pencil:before {content: "\e7B6";}
.hicon.toolset.pen:before {content: "\e7B7";}
.hicon.toolset.highlighter:before {content: "\e7B8";}
.hicon.variable.stroke0:before {content: "\e7BB";}
.hicon.variable.stroke1:before {content: "\e7BC";}
.hicon.variable.stroke2:before {content: "\e7BD";}
.hicon.variable.stroke3:before {content: "\e7BE";}
.hicon.variable.stroke4:before {content: "\e7BF";}
.hicon.signpost:before {content: "\e7C5";}
.hicon.flow.note0:before {content: "\e7C6";}
.hicon.flow.note1:before {content: "\e7C7";}
.hicon.flow.note2:before {content: "\e7C8";}
.hicon.flow.note3:before {content: "\e7C9";}
.hicon.flow.note4:before {content: "\e7CA";}
.hicon.flow.note5:before {content: "\e7CB";}
.hicon.flow.note6:before {content: "\e7CC";}
.hicon.stack:before {content: "\e7CD";}
.hicon.construction:before {content: "\e85f";}
.hicon.construction2:before {content: "\e85e";}
.hicon.vacation:before {content: "\e85b";}
.hicon.emoji:before {content: "\e7d2";}
.hicon.board:before {content: "\e7d8";}
.hicon.dispatch:before {content: "\e7a6";}
.hicon.edit:before {content: "\e61f";}

/* workspaces icons */
.hicon.blank:before {content: "\e68f";}
.hicon.business-model:before {content: "\e891";}
.hicon.kanban:before {content: "\e66a";}
.hicon.pi-planning:before {content: "\e896";}
.hicon.pullplanning:before {content: "\e7c3";}
.hicon.retrospective:before {content: "\e65c";}
.hicon.swot-analysis:before {content: "\e88f";}
.hicon.simple-drawing:before {content: "\e661";}
.hicon.value-proposition:before {content: "\e890";}
.hicon.weekly-planner:before {content: "\e7ce";}
.hicon.agile-planning:before {content: "\e892";}
.hicon.default:before {content: "\e682";}
.hicon.supply-chain:before {content: "\e655";}
.hicon.sketch:before {content: "\e760";}
.hicon.flow:before {content: "\e75f";}