@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html, body{
        margin:0;
        height:100%;
        min-height:100%;
    }
}

@layer utilities {
    .spinner {
        position: relative;
        margin: 100px auto;
    }

    .spinner.in-line {
        display: inline-block;
        margin: 0;
        position: absolute;
        width: 35px;
        height: 35px;
        margin-left: 10px;
    }

    .list-group-item .spinner {
        width: 33px;
        height: 33px;
        position: absolute;
        right: 0px;
        z-index: 100;
        margin: 0;
    }

    .double-bounce1,
    .double-bounce2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #333;
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;

        -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
        animation: sk-bounce 2.0s infinite ease-in-out;
    }

    .double-bounce2 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }

    @-webkit-keyframes sk-bounce {

        0%,
        100% {
            -webkit-transform: scale(0.0)
        }

        50% {
            -webkit-transform: scale(1.0)
        }
    }

    @keyframes sk-bounce {

        0%,
        100% {
            transform: scale(0.0);
            -webkit-transform: scale(0.0);
        }

        50% {
            transform: scale(1.0);
            -webkit-transform: scale(1.0);
        }
    }
}

@layer components {
    .w-fit {
        width: fit-content;
    }

    .customScrollbar,
    .multiselectDropdown ul.options {
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-color: #EFF3FA white;
        scrollbar-width: thin;
    }

    .customScrollbar::-webkit-scrollbar,
    .multiselectDropdown ul.options::-webkit-scrollbar  {
        background-color: #EFF3FA;
        width: 0.3rem;
    }

    .customScrollbar::-webkit-scrollbar-thumb,
    .multiselectDropdown ul.options::-webkit-scrollbar-thumb  {
        background-color: white;
    }
}